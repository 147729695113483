// note custom styles go here

// note Global **********************

//bootstrap media queries

@include media-breakpoint-up(xs) {
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-up(md) {
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(xl) {
}

.delivering_dreams {
    font-family: "Alex Brush", cursive;
}

h1.delivering_dreams {
    color: $gray-600;
    margin-bottom: -0.5rem;
    //  text-align: right;
    padding: 1rem;

    @include media-breakpoint-up(xs) {
        text-align: center;
        font-size: 260%;
    }

    @include media-breakpoint-up(sm) {
        font-size: 260%;
        text-align: center;
    }

    @include media-breakpoint-up(md) {
        text-align: center;
        font-size: 450%;
    }

    @include media-breakpoint-up(lg) {
        text-align: center;
        font-size: 650%;
    }
}

.delivering_dreams_subtitle {
    color: $gray-600;
}

main h1 {
    color: $gray-800;
}

main h2 {
    margin-bottom: 2rem;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $font-family-serif;
}
.tagline {
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 1px 1px 1px $gray-900;
}

.box_shadow {
    -webkit-box-shadow: 0 8px 6px -6px #999;
    -moz-box-shadow: 0 8px 6px -6px #999;
    box-shadow: 0 8px 6px -6px #999;
}

img.dreambays_logo_wide {
    max-height: 69px;
}

// note Header **********************

img.header_logo {
    // max-width: 75px;
    margin-right: 0.5rem;
    margin-top: -10px;
}
.header_info {
    color: #fff;
}

a.navbar-brand {
    font-family: $font-family-serif;
    text-transform: uppercase;
    font-size: 88%;
    letter-spacing: 1px;
}

.nav-item {
    text-transform: uppercase;
    font-size: 88%;

    a.nav-link {
        font-family: $font-family-sans-serif;
        color: $white;
        -webkit-transition: all ease-in-out 0.75s;
        -moz-transition: all ease-in-out 0.75s;
        -o-transition: all ease-in-out 0.75s;
        transition: all ease-in-out 0.75s;
        margin-left: 1rem;
        &:hover {
            text-decoration: overline;
        }
    }
}

// index sub_banner

img.sub_banner {
}

// note Body **********************

// index Home
body.home {
    li a.home_link {
        text-decoration: overline;
    }

    img.lucas_portrait {
        float: none;
        max-width: 310px;
        padding: 1rem;
        margin: 0 auto;

        @include media-breakpoint-up(xs) {
        }

        @include media-breakpoint-up(md) {
        }

        @include media-breakpoint-up(lg) {
            float: left;
            padding-right: 2rem;
        }
    }
}

body.attorney_profile {
    li a.attorney_profile_link {
        text-decoration: overline;
    }
}
body.areas_of_law {
    li a.areas_of_law_link {
        text-decoration: overline;
    }
}
body.cases {
    li a.cases_link {
        text-decoration: overline;
    }
}
body.resources {
    li a.resources_link {
        text-decoration: overline;
    }
}
body.contact {
    li a.contact_link {
        text-decoration: overline;
    }
}

.dropdown-item {
    font-size: 88%;
}

.accordion {
    h1,
    .h1 {
        @include media-breakpoint-up(xs) {
            font-size: 1rem;
        }

        @include media-breakpoint-up(sm) {
            font-size: 1rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 2.39rem;
        }
    }
}

h1 small {
    line-height: 2;
}

// note serif font in all main content area

article,
p,
main {
    font-family: $font-family-serif;
}

main {
    min-height: 850px;
    padding-bottom: 4rem;
}

main.home {
    padding-bottom: 0;
}

// index attorney profile page
.profile-client-list {
    ul {
        list-style-type: none;

        li {
            padding-left: 1rem;
        }

        li:first-child {
            padding-left: 0rem;
        }
    }
}

// index Article, Cards, fields, etc. **********************

article,
main,
.entry-content {
    background: $gray_light;
}

.newspaper_cols {
    background-color: #fff;
    padding: 1rem;
    margin-bottom: 2.5rem;
    box-shadow: 0 5px 3px $gray-300;
    min-height: 250px;
}

//index home

.home_welcome_title {
    //bottom: 3rem;
    height: 0;
    z-index: 10;
    position: relative;
    text-align: center;
    padding: 0;
    top: 8rem;
    font-size: 85%;
    color: $white;
    text-shadow: 6px 2px 7px #000;
    font-size: 100%;

    a.btn {
        background-color: rgba(0, 0, 0, 0.4); //transparent
        color: $white; 
        padding: 1rem;       
        font-size: 95%;    

        &:hover {
            background-color: rgba(0, 0, 0, 0.3); //transparent
        }
    }

    @include media-breakpoint-up(md) { 
        top: 5rem;
    }
    @include media-breakpoint-up(lg) {   
        top: 12rem;
    }

    @include media-breakpoint-up(xl) {   
        top: 23rem;
    }
    //index welcome button caption carousel
 }



// NOTE cgbays2022 Gallery
.gallery {
    h2 {
        margin: 2rem 0 2rem 0;
    }
}

.container.gallery {
    margin: 3rem 0 2rem 0;

    h1,
    h2,
    h3 {
        margin: 3rem 0 1rem 0;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
        font-size: 135%;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $dreambays_blue;
        padding: 3px;
    }
}

// NOTE dreambasy2021 blog card

.card-body {
    line-height: 1.25;
    padding: 0.75rem;
    font-size: 95%;

    div {
        margin-bottom: 5px;
    }

    div:last-child {
        margin-bottom: 0;
    }

    a {
        font-size: 93%;
    }
}

//note rebuilt parts compatibility category
.compatibility_cat {
    // in the rebuilt parts title, the div of what the parts are for
    background: $dreambays_silver_light;
    font-size: 82%;
    padding: 4px 4px 4px 8px;
    margin-top: 12px;
}

.rebuilt-parts {
    .modal {
        .modal-body {
            div {
                margin-bottom: 6px;
            }
        }
    }

    img.card-img-top {
        opacity: 0.9;
        filter: alpha(opacity=90);
        -webkit-transition: background ease-in-out 0.25s;
        -moz-transition: background ease-in-out 0.25s;
        -o-transition: background ease-in-out 0.25s;
        transition: background ease-in-out 0.25s;

        &:hover {
            opacity: 1;
            filter: alpha(opacity=100);
        }
    }
}

.parts_title {
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    font-size: 93%;
    margin-bottom: 5px;
}

.field_label {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 75%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $dreambays_blue;
    padding: 3px;
}

// .field_data {

// }

.parts_core_charge {
    .field_data {
        font-size: 86%;
    }
}

// note rebuilt-parts compatibility table
.compatibility_table {
    td,
    th,
    tr {
        border: 1px $gray-500 dotted;
        font-size: 85%;
        padding: 6px;
    }

    tr {
        &:nth-child(odd) {
            background: $gray-100;
        }
    }
}

// note Footer **********************

// note parts query form is in footer

.query_form_accordion_card {
    a {
        color: $dreambays_blue;

        &:hover {
            text-decoration: none;
            color: $dreambays_blue_dark;
        }
    }

    small.text-muted {
        color: $dreambays_blue;
    }

    border: 1px solid white;
    -webkit-transition: all ease-in-out 0.125s;
    -moz-transition: all ease-in-out 0.125s;
    -o-transition: all ease-in-out 0.125s;
    transition: all ease-in-out 0.125s;

    &:hover {
        border: 1px solid $dreambays_silver_light;
    }
}

.contact_form {
    label {
        display: block;
        margin: 1rem 0 1rem 0;
    }
}

footer.bg_footer {
    background-color: #1d0604;
    background-image: url(https://nascimentolaw.com/assets/img/bg_classy_fabric.png);
}

img.map_in_footer {
    -webkit-transition: all ease-in-out 0.25s;
    -moz-transition: all ease-in-out 0.25s;
    -o-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s;
    filter: brightness(80%);
    &:hover {
        filter: brightness(105%);
    }
}
.address_in_footer {
    font-family: $font-family-sans-serif;
    font-size: 95%;
}

footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: $white;

    .contact_form {
        color: $black;
    }

    .credits_last_line {
        text-transform: uppercase;
        font-size: 62%;
        font-family: $font-family-sans-serif;
    }

    a {
        color: $white;

        &:hover {
            color: $dreambays_gold_light;
        }
    }

    p {
        margin-bottom: 0.25rem;
    }
}
